import React from 'react'

/**
 * Auth Context
 */
const AppContext = React.createContext({
  uiTheme: '',
  setUiTheme: () => { },
})

export default AppContext
