import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
  },
  {
    path: '/errors',
    component: lazy(() => import('src/layouts/Errors')),
    routes: [
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/home',
    component: lazy(() => import('src/layouts/Home')),
    routes: [
      {
        path: '/home',
        exact: true,
        component: lazy(() => import('src/views/Home')),
      },
      {
        path: '/home/verify-email',
        exact: true,
        component: lazy(() => import('src/views/VerifyEmail')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: '*',
    component: lazy(() => import('src/layouts/Home')),
    routes: [
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
]
