import React, { useState, useEffect, Suspense } from 'react'
import { Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { createBrowserHistory } from 'history'
import routes from './routes'
import AppContext from './AppContext'
import ScrollReset from './components/ScrollReset'
import {
  gaRegisterGenericEvent,
  gaRegisterPageViewEvent,
} from './utils/googleAnalytics'

const history = createBrowserHistory()

function App() {
  // get access level from local store
  const initUiTheme =
    typeof window !== 'undefined' ? localStorage.getItem('uiTheme') : null
  const [uiTheme, setUiTheme] = useState(initUiTheme || 'light')

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          // type: prefersDarkMode ? 'dark' : 'light',
          type: uiTheme,
          primary: { main: '#f08a5d' },
          secondary: { main: '#f00a0a' },
        },
      }),
    [uiTheme]
  )

  useEffect(() => {
    // Sets the theme config to localstore
    if (uiTheme) {
      gaRegisterGenericEvent('Theme', 'ToggleTheme', uiTheme)
      localStorage.setItem('uiTheme', uiTheme)
    }
  }, [uiTheme])

  useEffect(() => {
    gaRegisterPageViewEvent()
  }, [])

  return (
    <AppContext.Provider
      value={{
        uiTheme,
        setUiTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <ScrollReset />
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(routes)}
          </Suspense>
        </Router>
      </ThemeProvider>
    </AppContext.Provider>
  )
  // ****************************************************** END
}

export default App
