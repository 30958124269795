import ReactGA from 'react-ga'

// Set Tracking ID
const trackingId = 'UA-134677718-3' // Replace with your Google Analytics tracking ID

// Initialize
ReactGA.initialize(trackingId)

/**
 * Google Analytics Register Page View Event
 */
export const gaRegisterPageViewEvent = () => {
  // use only in production
  if (process.env.NODE_ENV === 'production') {
    // google analytics page view tracking
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}

/**
 * Register Generic Event in Google Analytics
 * @param {string} category Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
 * @param {string} action Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
 * @param {string} label Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
 * @param {number} value Optional. A means of recording a numerical value against an event. E.g. a rating, a score, etc.
 */
export const gaRegisterGenericEvent = (
  category,
  action,
  label = null,
  value = null
) => {
  // use only in production
  if (process.env.NODE_ENV === 'production' && category && action) {
    const config = { category, action }
    if (label) {
      config.label = label
    }
    if (value !== null && typeof value === 'number') {
      config.value = value
    }
    ReactGA.event({
      ...config,
    })
  }
}

export default ReactGA
